import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

export default function Organizations() {
  const [orgs, setOrgs] = useState([]);
  const [lastSort, setLastSort] = useState("");
  const [loading, setLoading] = useState(true);
  const [imp, setImport] = useState(false);
  const [files, setFiles] = useState();
  const [err, setErr] = useState("");
  const [wait, setWait] = useState("");
  const [msg, setMsg] = useState("");
  const [err2, setErr2] = useState(<></>);

  const getAll = () => {
    setLoading(true);
    axios
      .get("/api/org/all")
      .then((r) => setOrgs(r.data))
      .finally(setLoading(false));
  };

  useEffect(() => {
    getAll();
  }, []);

  const compare = (key) => {
    if (lastSort !== key) {
      return function (a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      };
    } else {
      return function (a, b) {
        if (a[key] < b[key]) return 1;
        if (a[key] > b[key]) return -1;
        return 0;
      };
    }
  };

  const sortBy = (type) => {
    let copy = [...orgs];
    copy.sort(compare(type));
    setOrgs(copy);
    if (lastSort === type) setLastSort("");
    else setLastSort(type);
  };

  const goTo = (id) => {
    window.location = "/organizations/" + id;
  };

  const importFile = (e) => {
    console.log(e);
    setFiles(e[0]);
  };

  const runImport = (e) => {
    e.preventDefault();
    if (!files) setErr("Please input a file");
    setWait(" wait");
    const reqConfig = {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    };
    const data = new FormData();
    data.append("file", files, files.name);
    axios
      .post("/api/org/upload", data, reqConfig)
      .then((res) => {
        setMsg("Uploaded");
        //window.location.reload()
      })
      .catch((e) => {
        let m = e.response.data.errors.map((er) => {
          const em = er.error ? er.error : er.missingElements.toString();
          const me = er.missingElements ? " missing elements" : "";
          return (
            <p className="Error">
              In {er.email} found{me}: {em}
            </p>
          );
        });
        setErr2(m);
        setErr(e.response.data.message);
      })
      .finally(() => {
        getAll();
        setWait("");
      });
  };

  return (
    <div className="wide">
      <h1>Organizations</h1>
      {loading && <LinearProgress />}
      <div className="tableWrapper">
        <table className="eventsTable">
          <tr>
            <th
              className="clickable"
              onClick={() => sortBy("organization_name")}
            >
              Organization name &#8645;
            </th>
            <th className="clickable" onClick={() => sortBy("email")}>
              Email &#8645;
            </th>
            <th className="clickable" onClick={() => sortBy("confirmed")}>
              Confirmed &#8645;
            </th>
            <th className="clickable" onClick={() => sortBy("last_login")}>
              Latest login &#8645;
            </th>
          </tr>
          {orgs.filter(o => o.type === 'test driver').map((org) => {
            const name = org.organization_name
              ? org.organization_name
              : "Not yet logged in";
            const bid = org.business_id ? org.business_id : "Not yet logged in";
            return (
              <tr onClick={() => goTo(org.id)} key={org.id}>
                <td>{name}</td>
                <td>{org.email}</td>
                <td>{org.confirmed ? "Confirmed" : "Not confirmed"}</td>
                <td>{org.last_login || "None"}</td>
              </tr>
            );
          })}
        </table>
      </div>
      <h1>Manufacturers and admins</h1>
      <div className="tableWrapper">
        <table className="eventsTable">
          <tr>
            <th
              className="clickable"
              onClick={() => sortBy("organization_name")}
            >
              Organization name &#8645;
            </th>
            <th className="clickable" onClick={() => sortBy("business_id")}>
              Business ID &#8645;
            </th>
            <th className="clickable" onClick={() => sortBy("email")}>
              Email &#8645;
            </th>
            <th className="clickable" onClick={() => sortBy("type")}>
              Type &#8645;
            </th>
            <th className="clickable" onClick={() => sortBy("confirmed")}>
              Confirmed &#8645;
            </th>
            <th className="clickable" onClick={() => sortBy("last_login")}>
              Last login &#8645;
            </th>
          </tr>
          {orgs.filter(o => o.type !== 'test driver').map((org) => {
            const name = org.organization_name
              ? org.organization_name
              : "Not yet logged in";
            const bid = org.business_id ? org.business_id : "Not yet logged in";
            return (
              <tr onClick={() => goTo(org.id)} key={org.id}>
                <td>{name}</td>
                <td>{bid}</td>
                <td>{org.email}</td>
                <td>{org.type}</td>
                <td>{org.confirmed ? "Confirmed" : "Not confirmed"}</td>
                <td>{org.last_login || "None"}</td>
              </tr>
            );
          })}
        </table>
      </div>
      <div className="buttonFieldset">
        <Link to="/organizations/add" className="button submitButton">
          Add organization
        </Link>
        <button className="button submitButton" onClick={() => setImport(!imp)}>
          Import
        </button>
      </div>
      {imp && (
        <div>
          <div className="fieldset">
            <label htmlFor="file">Import file (.xlsx)</label>
            <input
              type="file"
              id="file"
              accept=".xlsx"
              onChange={(e) => importFile(e.target.files)}
            />
          </div>
          <div className="buttonFieldset">
            <button
              className={"button submitButton" + wait}
              onClick={(e) => runImport(e)}
            >
              {wait ? <CircularProgress color="grey" size="1rem" /> : "Upload"}
            </button>
          </div>
          {msg && (
            <div>
              <b>{msg}</b>
            </div>
          )}
          {err && (
            <>
              <p className="Error">{err}</p>
              {err2}
            </>
          )}
        </div>
      )}
    </div>
  );
}
